<template>
  <div class="stepper" :style="{ width: typeof width === 'number' ? `${width}px` : width, height: typeof height === 'number' ? `${height}px` : height }">
    <div class="stepper-progress">
      <div class="stepper-progress-bar">
        <div class="stepper-progress-percentage" :style="{ width: stepperProgress}"></div>
      </div>
      <div
        v-for="node in stepNodes"
        :key="node.step"
        class="stepper-node"
        :class="node.step > currentNode.step ? undefined : node.status"
      >
        <div
          class="stepper-counter"
          :style="{ cursor: finished || !jumpable || node.step >= currentNode.step ? 'not-allowed' : 'pointer' }"
          @click="jump(node.step)"
        >
          <FontAwesome v-if="node.status === 'validated'" class="stepper-icon" icon="check" />
          <FontAwesome v-else-if="node.icon" class="stepper-icon" :icon="node.icon" />
          <span v-else class="stepper-number">{{ node.step }}</span>
        </div>
        <div class="stepper-title" v-html="node.title" />
      </div>
    </div>
    <div class="stepper-container">
      <div
        class="stepper-tab"
        v-for="node in stepNodes"
        :key="node"
        v-show="currentNode.step == node.step"
      >
        <div v-if="!hintHidden && node.hint && node.hintPosition === 'left'" class="stepper-hint--left" :style="{ minWidth: typeof node.hintWidth === 'number' ? `${node.hintWidth}px` : node.hintWidth }">
          <slot :name="node.hint" :node="node" :nodes="stepNodes" />
        </div>
        <div class="stepper-content">
          <slot v-if="node.template" :name="node.template" :node="node" :nodes="stepNodes" />
          <div v-else-if="node.content" v-html="node.content" />
        </div>
        <div v-if="!hintHidden && node.hint && node.hintPosition !== 'left'" class="stepper-hint--right" :style="{ minWidth: typeof node.hintWidth === 'number' ? `${node.hintWidth}px` : node.hintWidth }">
          <slot :name="node.hint" :node="node" :nodes="stepNodes" />
        </div>
      </div>
    </div>
    <slot v-if="!finished" name="controls" :node="node" :nodes="stepNodes" >
      <div class="stepper-controls">
        <button
          class="stepper-button"
          :disabled="backable && currentNode.step == stepNodes[0].step"
          @click="back()"
        >
          <FontAwesome class="w-2 mr-1" icon="angle-left" />
          返回
        </button>

        <button
          class="stepper-button"
          type="skip"
          :disabled="!currentNode.skipable"
          @click="skip()"
        >
          <FontAwesome class="w-2 mr-1" icon="angle-double-right" />
          跳過
        </button>

        <button
          v-if="hasHint"
          class="stepper-button"
          type="hint"
          :disabled="!currentNode.hint"
          @click="toggleHint"
        >
          <FontAwesome class="w-2 mr-1" icon="question" />
          {{`${hintHidden? '顯示' : '隱藏'}提示`}}
        </button>

        <slot name="buttons" :node="node" :nodes="stepNodes" />

        <button
          class="stepper-button"
          type="reset"
          :disabled="!currentNode.reset"
          @click="() => { if (currentNode.reset) currentNode.reset(currentNode); }"
        >
          <FontAwesome class="w-2 mr-1" icon="undo" />
          重置
        </button>

        <button
          class="stepper-button"
          :type="currentNode.step !== stepNodes[stepNodes.length - 1].step ? 'continue' : 'complete'"
          @click="() => { currentNode.step !== stepNodes[stepNodes.length - 1].step ? next() : complete(); }"
        >
          <FontAwesome class="w-2 mr-1" :icon="currentNode.step !== stepNodes[stepNodes.length - 1].step ? 'angle-right' : 'check'" />
          {{currentNode.step !== stepNodes[stepNodes.length - 1].step ? '繼續' : '完成'}}
        </button>
      </div>
    </slot>
    <slot v-else name="finish-content" :nodes="stepNodes">
      <div class="stepper-controls">
        <button class="stepper-button" type="finish">
          <FontAwesome class="w-2 mr-2" icon="award" />
          已完成所有步驟
          <FontAwesome class="w-2 ml-2" icon="award" />
        </button>
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "@cloudfun/core";
import "./Stepper.scss";

export interface IStepperNode {
  /** 步驟編號 */
  step: number,
  /** 標題，可為純文字或是HTML */
  title: string,
  /** 內容，可為純文字或是HTML。與 template 擇一使用 */
  content?: string,
  /** 樣板名稱，須提供同名稱之樣板。與 content 擇一使用 */
  template?: string,
  /** 提示的樣板名稱 */
  hint?: string,
  /** 提示的位置 */
  hintPosition?: 'left' | 'right',
  /** 提示的寬度 */
  hintWidth?: number | string,
  /** 資料，供樣板使用 */
  data?: any,
  /** 可跳過 */
  skipable?: boolean,
  /** 驗證資料 */
  validate?(node: IStepperNode, nodes: IStepperNode[]): Promise<boolean>,
  /** 重置資料 */
  reset?(node: IStepperNode, nodes: IStepperNode[]): Promise<void>,
  /** 狀態 */
  status?: 'current' | 'skiped' | 'validated'
}

export interface IStepperOptions {
  /** 步驟節點 */
  nodes: IStepperNode[],
  /** 允許返回上個步驟 */
  backable?: boolean,
  /** 允許跳至之前步驟 */
  jumpable?: boolean,
  /** 寬 */
  width?: number | string,
  /** 高 */
  height?: number | string,
}

export default defineComponent({
  props: {
    node: { type: Object as PropType<IStepperNode> },
    nodes: { type: Array as PropType<IStepperNode[]>, required: true },
    backable: { type: Boolean, default: true },
    jumpable: { type: Boolean, default: true },
    width: { type: [Number, String] },
    height: { type: [Number, String] },
    onBack: { type: Function as PropType<(from: IStepperNode, to: IStepperNode, nodes: IStepperNode[]) => void> },
    onSkip: { type: Function as PropType<(from: IStepperNode, to: IStepperNode, nodes: IStepperNode[]) => void> },
    onJump: { type: Function as PropType<(from: IStepperNode, to: IStepperNode, nodes: IStepperNode[]) => void> },
    onNext: { type: Function as PropType<(from: IStepperNode, to: IStepperNode, nodes: IStepperNode[]) => void> },
    onComplete: { type: Function as PropType<(nodes: IStepperNode[]) => void> },
  },
  setup(props) {
    let stepNodes = [...props.nodes];
    stepNodes = stepNodes.sort((a, b) => a.step - b.step);

    const hasHint = ref(stepNodes.some(e => e.hint));
    const hintHidden = ref(!hasHint);

    const currentNode = ref(stepNodes[0]);
    currentNode.value.status = 'current';

    const stepperProgress = computed(() => 100 / (stepNodes.length - 1) * stepNodes.indexOf(currentNode.value) + '%');

    return {
      currentNode,
      stepNodes,
      hasHint,
      stepperProgress,
      hintHidden,
      finished: ref(false)
    };
  },
  methods: {
    back() {
      const index = this.stepNodes.indexOf(this.currentNode);
      if (index > 0) {
        const to = this.stepNodes[index - 1];
        const action = () => {
          this.currentNode.status = undefined;
          this.currentNode = to;
          this.currentNode.status = 'current';
        };
        if (this.$props.onBack) this.$emit("back", { from: this.currentNode, to, nodes: this.stepNodes }, action);
        else action();
      }
    },
    skip() {
      const index = this.stepNodes.indexOf(this.currentNode);
      if (this.stepNodes.length === index + 1) {
        if (this.$props.onComplete) this.$emit("complete", this.stepNodes);
      } else {
        const to = this.stepNodes[index + 1];
        const action = () => {
          this.currentNode.status = 'skiped';
          this.currentNode = to;
          this.currentNode.status = 'current';
        };
        if (this.$props.onSkip) this.$emit("skip", { from: this.currentNode, to, nodes: this.stepNodes }, action);
        else action();
      }
    },
    jump(to: number, force: boolean) {
      if (!force && (this.finished || !this.jumpable || to >= this.currentNode.step)) return;
      const index = this.stepNodes.indexOf(this.currentNode);
      if (index > 0) {
        const toStep = this.stepNodes.find(e => e.step === to) || this.currentNode;
        const action = () => {
          this.stepNodes.filter(e => e.step > to && e.step <= this.currentNode.step).forEach(e => { e.status = undefined });
          this.currentNode = toStep;
          this.currentNode.status = 'current';
          this.finished = false;
        };
        if (this.$props.onJump) this.$emit("jump", { from: this.currentNode, to, toStep: this.stepNodes }, action);
        else action();
      }
    },
    async next() {
      if (!this.currentNode.validate || await this.currentNode.validate(this.currentNode, this.stepNodes)) {
        const index = this.stepNodes.indexOf(this.currentNode);
        const to = this.stepNodes[index + 1];
        const action = () => {
          this.currentNode.status = 'validated';
          this.currentNode = to;
          this.currentNode.status = 'current';
        };
        if (this.$props.onNext) this.$emit("next", { from: this.currentNode, to, nodes: this.stepNodes }, action);
        else action();
      }
    },
    async complete() {
      if (!this.currentNode.validate || await this.currentNode.validate(this.currentNode, this.stepNodes)) {
        const action = () => {
          this.currentNode.status = 'validated';
          this.finished = true;
        };
        if (this.$props.onComplete) this.$emit("complete", this.stepNodes, action);
        else action();
      }
    },
    toggleHint() {
      this.hintHidden = !this.hintHidden;
    }
  }
});
</script>
